import en from './en.js';
import es from './es.js';
import pt from './pt.js';

const translations = {
	en,
	es,
	pt
};

export { translations as default };
