import React, { useEffect, useState } from 'react';
import './GameModal.css';
import Modal from '../Modal';
import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from '../../hooks/useGlobalState';
import { FormattedMessage } from 'react-intl';
import StickyMenu from './StickyModal/StickyMenu';
import GameDetails from '../GameDetails/GameDetails';
import GameFeatureDescription from '../GameFeatureDescription/GameFeatureDescription';
import SimilarGames from '../SimilarGames/SimilarGames';
import bannerMain from '../../assets/banner420.webp';
// import bannerDrops from '../../assets/banner-drop.png';
// import bannerChallenges from '../../assets/banner-challenge.png';
import tabletBack from '../../assets/tabletBack.svg';
import tabletInfo from '../../assets/tabletInfo.svg';
import tabletStar from '../../assets/tabletStar.svg';
import Banner from '../Banner';
import AboutGameModal from './AboutGameModal';
import '../../assets/styles/AboutGameModal.css';
import { isMobile } from 'react-device-detect';

const GameModal = ({ handleLanguageChange, handlePlayLink, setCategory }) => {
	const [stateSimilarGames, setStateSimilarGames] = useState(null);
	const [isShowSimilarGames, setShowSimilarGames] = useState(true);
	const [aboutGameModal, setAboutGameModal] = useState(false);
	const languages = ['en', 'es', 'pt'];
	const { state, dispatch } = useGlobalState();

	const clearGameSrc = () => {
		dispatch({ type: ACTION_TYPE.CLEAR_GAME_SRC });
	};

	useEffect(() => {
		if (state.gameID) {
			const selectedGame = state.games.find((game) => game.GameID === state.gameID);

			const similarGames = state.games.filter(
				(game) => game.GameType === selectedGame.GameType && game.GameID !== state.gameID
			);

			setStateSimilarGames(similarGames.length ? similarGames : null);
		}
	}, [state.gameID]);

	if (!state.gameSrc) {
		return null;
	}

	const ChildComponent = () => {
		console.log(state.selectedFeature, ' state.selectedFeature ');
		const components = {
			[SELECTED_FEATURE.BASE_GAME]: <GameDetails />,
			[SELECTED_FEATURE.CHALLENGES]: (
				<GameFeatureDescription selectedFeature={SELECTED_FEATURE.CHALLENGES} clearGameSrc={clearGameSrc} />
			),
			[SELECTED_FEATURE.DROP]: (
				<GameFeatureDescription selectedFeature={SELECTED_FEATURE.DROP} clearGameSrc={clearGameSrc} />
			)
		};
		return components[state.selectedFeature] || null;
	};

	const handleSimilarButton = () => {
		setShowSimilarGames((prevState) => !prevState);
	};

	const SimilarButton = () => (
		<button className={`game-modal-button game-modal-button_siminal`} onClick={handleSimilarButton}>
			<FormattedMessage id="similarGames" />
		</button>
	);

	const switchLanguage = (language) => {
		dispatch({ type: ACTION_TYPE.SET_LANGUAGE, payload: language });
	};

	const handleLanguageSwitcher = (language) => {
		if (language !== state.language) {
			switchLanguage(language);
		}
	};

	const closeAndSetCategory = (category) => {
		clearGameSrc();
		setCategory(category);
	};

	return (
		<>
			<Modal className={isMobile ? "game-modal game-modal_mobile" : "game-modal"}>
				<div className={isMobile ? "game-modal-template_mobile" : "game-modal-template"}>
					<div className={isMobile ? "game-modal_banner_mobile" : "game-modal_banner"}>
						<Banner src={bannerMain} />
					</div>

					<div className={isMobile ? "game-modal-iframe_wrapper game-modal-iframe_wrapper_mobile" : "game-modal-iframe_wrapper"}>
						<iframe
							className="game-modal-iframe"
							src={state.gameSrc}
							title="game"
							allow="autoplay"
							width="100%"
							height="100%"
						/>
					</div>
					<div className={isMobile ? "game-modal-content_template_mobile" : "game-modal-content_template"}>
						{/*<div className={style.button_container}>*/}
						{/*	<button className={`${style.button} ${style.button_back}`} onClick={clearGameSrc}>*/}
						{/*		← <FormattedMessage id="back" />*/}
						{/*	</button>*/}
						{/*	{stateSimilarGames && <SimilarButton />}*/}
						{/*</div>*/}
						<ChildComponent />
						{state.selectedFeature === 'BASE_GAME' && (
							<SimilarGames
								isVisible={isShowSimilarGames}
								similarGames={stateSimilarGames}
								handlePlayLink={handlePlayLink}
							/>
						)}
						<div className="game-modal-empty_block"></div>
						<StickyMenu onLanguageChange={handleLanguageChange} />
					</div>
				</div>

				<div className={isMobile ? "game-modal_tablet-buttons game-modal_tablet-buttons_mobile" : "game-modal_tablet-buttons"}>
					<div className="game-modal_tablet-btn" onClick={clearGameSrc}>
						<img src={tabletBack} alt="back" />
						<span>
							<FormattedMessage id="back" />
						</span>
					</div>
					<div className="game-modal_tablet-center">
						<div className="game-modal_tablet-btn" onClick={() => setAboutGameModal(true)}>
							<img src={tabletInfo} alt="info" />
							<span>
								<FormattedMessage id="aboutGame" />
							</span>
						</div>
						<div className="game-modal_tablet-btn" onClick={() => closeAndSetCategory('topLatamGames')}>
							<img src={tabletStar} alt="star" />
							<span>
								<FormattedMessage id="topLatamGames" />
							</span>
						</div>
					</div>
					<div className="game-modal_tablet-lang">
						{languages.map((lang) => {
							return (
								<span
									className={
										lang === state.language
											? 'game-modal_tablet-lang-btn game-modal_tablet-lang-btn_active'
											: 'game-modal_tablet-lang-btn'
									}
									key={lang}
									onClick={() => handleLanguageSwitcher(lang)}
								>
									{lang}
								</span>
							);
						})}
					</div>
				</div>
			</Modal>
			{aboutGameModal && <AboutGameModal setAboutGameModal={setAboutGameModal} />}
		</>
	);
};

export default GameModal;
