export default {
	resetFilters: 'Reset Filters',
	noSearchResults: 'No search results',
	noGamesWithSearchEntry: 'There are no games with this search entry.',
	showMore: 'Mostrar mais',

	// Filters Game Type
	GameType: 'Tipo de jogo',
	topLatamGames: 'MELHORES JOGOS DA LATAM',
	new: 'NOVO!',
	slots: 'Slots',
	cards: 'CARTAS',
	casual: 'Casual',
	dice: 'Dice',
	roulette: 'Roleta',
	scratch: 'Raspe',
	lottery: 'Loteria',

	// Filters Game Features
	GameFeatures: 'Features',
	respins: 'Respins',
	'bonus-game': 'Bonus Game',
	'buy-bonus': 'Buy Bonus',
	'buy-chance': 'Buy Chance',
	'expanding-wild': 'Expanding Wild',
	'gamble-round': 'Gamble Round',
	'in-game-jackpot': 'In-game Jackpot',
	'lines-change': 'Lines Change',
	'pay-both-ways': 'Pay Both Ways',
	'provably-fair': 'Provably Fair',
	'sticky-wilds': 'Sticky Wilds',

	// Filters Volatility
	Volatility: 'Volatilidade',
	'volatility-low': 'Low',
	'volatility-medium-low': 'Medium-low',
	'volatility-medium': 'Medium',
	'volatility-medium-high': 'Medium-high',
	'volatility-high': 'High',
	'volatility-very-high': 'Very high',

	// Filters GameMechanics
	GameMechanics: 'Mechanics',
	Megaways: 'Megaways',
	Trueways: 'Trueways',
	'Refilling Reels': 'Refilling Reels',
	'Cluster Pays': 'Cluster Pays',
	'Hold&Win': 'Hold&Win',
	Crash: 'Crash',
	'Book-Of': 'Book-Of',

	// Filters PlayLines
	PlayLines: 'Lines',
	1: '1',
	5: '5',
	9: '9',
	10: '10',
	15: '15',
	20: '20',
	25: '25',
	40: '40',
	50: '50',
	100: '100',
	243: '243',
	Megaways: 'Megaways',
	'Cluster Pays': 'Cluster Pays',
	Trueways: 'Trueways',
	'Pays Anywhere': 'Pays Anywhere',

	// Filters Game Theme
	all: 'TODOS',
	GameTheme: 'Theme',
	adventure: 'Adventure',
	animals: 'Animals',
	asia: 'Asia',
	aztec: 'Aztec',
	beer: 'Beer',
	book: 'Book',
	cars: 'Cars',
	chinese: 'Chinese',
	classic: 'Classic',
	clovers: 'Clovers',
	cocktails: 'Cocktails',
	coins: 'Coins',
	dice: 'DADOS',
	easter: 'Easter',
	egypt: 'Egypt',
	fantasy: 'Fantasy',
	farm: 'Farm',
	fruits: 'Fruits',
	fun: 'Fun',
	gems: 'Gems',
	gifts: 'Gifts',
	girls: 'Girls',
	gold: 'Gold',
	heist: 'Heist',
	joker: 'Joker',
	lady: 'Lady',
	magic: 'Magic',
	medieval: 'Medieval',
	money: 'Money',
	monster: 'Monster',
	music: 'Music',
	myths: 'Myths',
	oriental: 'Oriental',
	party: 'Party',
	scary: 'Scary',
	sea: 'Sea',
	space: 'Space',
	sport: 'Sport',
	'steam-punk': 'Steam punk',
	sweets: 'sweets',
	tramp: 'Tramp',
	treasure: 'Treasure',
	'wild-west': 'wild-west',
	xmas: 'Xmas',

	// Other buttons
	playDemo: 'Play demo',
	backToGames: 'BACK TO GAMES',
	goUp: 'Go up',
	search: 'Buscar',
	back: 'VOLTAR',
	similarGames: 'Jogos semelhantes',
	play: 'JOGAR',

	allGames: 'Todos os jogos',
	gamification: 'Gamificação',
	aboutUs: 'Sobre nós',
	casinoBeatsTitle: 'Casinobeats Game Developer Awards 2023',
	bestSoftwareProvider: 'Melhor fornecedor de software',
	bestNewSlot: 'Melhor novo caça-níqueis',
	bgamingThumbs: 'BGAMING & THUMBS',
	bgamingThumbsText:
		'Nossa iniciativa "Quando a Arte Encontro Jogo" dá seu primeiro passo brilhante com o talentoso artista londrino Thumbs. Com seu estilo distinto inspirado na cultura pop, ele reinterpretou organicamente personagens dos jogos da BGaming, como Elvis Frog, Joker Queen e Cactus Cowboy Johnny Cash, adicionando um toque especial à nossa experiência na ICE London 2023.',
	other: 'OUTROS',
	letStartConversation: 'Vamos começar uma conversa',
	wouldYouLikeToStartConversation: 'Gostaria de iniciar  uma conversa sobre BGaming sobre qualquer área?',

	//details
	releaseDate: 'Data de lançamento',
	maxMultiplier: 'Multiplicador máximo',
	maxWin: 'Vitória máxima',

	//drops description
	dropsTitle: 'BGaming Drops',
	dropsSubtitle:
		'BGaming Drops é uma ferramenta poderosa para operadores de jogos online que buscam melhorar o engajamento e a retenção dos jogadores. Ao oferecer recompensas aleatórias e personalizáveis, o BGaming Drops não só torna o jogo mais emocionante, mas também fornece aos operadores uma solução de marketing flexível e eficaz. Seja para promoções sazonais ou incentivos para jogadores recorrentes, o BGaming Drops pode ser ajustado para atender às necessidades individuais de qualquer plataforma de jogos.',
	dropsLoyaltyTitle: 'Maior fidelidade do jogador',
	dropsLoyaltySubtitle:
		'Recompensas regulares fazem os jogadores retornarem, promovendo fidelidade e aumentando seu valor vitalício. ',

	dropsToolTitle: 'Ferramenta de Marketing Flexível',
	dropsToolSubtitle:
		'Os operadores podem personalizar as campanhas de drops para alinhá-lasaos seus objetivos promocionais e às preferências dos jogadores.',

	dropsMotivatesTitle: 'Jogabilidade aprimorada',
	dropsMotivatesSubtitle: 'A antecipação de receber um drop motiva os jogadores a prolongar suas sessões de jogo.',

	dropsExperienceTitle: 'Experiência do jogador melhorada ',
	dropsExperienceSubtitle:
		'Os drops adicionam uma camada extra de emoção e gratificação, enriquecendo a experiência geral de jogo.',

	//challenges description
	challengesTitle: 'BGaming Challenges',
	challengesSubtitle:
		'BGaming Challenges é um recurso inovador projetado para aumentar o engajamento e a retenção dos jogadores,incorporando uma série de desafios emocionantes e recompensadores dentro do jogo. Esta ferramenta permite que os operadores criem desafios personalizados dentro dos títulos da BGaming, oferecendo aos jogadores metas e recompensas adicionais que vão além da jogabilidade padrão.',

	challengesMotivationTitle: 'Maior Motivação do Jogador',
	challengesMotivationSubtitle:
		'Os desafios oferecem aos jogadores metas adicionais, motivando-os a jogar mais e alcançar marcos.',

	challengesToolTitle: 'Ferramenta de Marketing Flexível',
	challengesToolSubtitle:
		'Os operadores podem criar desafios que se alinham com suas estratégias de marketing e preferências dos jogadores.',

	challengesRatesTitle: 'Taxas de Engajamento Mais Altas',
	challengesRatesSubtitle: 'Desafios envolventes e diversos incentivam os jogadores a passar mais tempo no jogo.',

	challengesExperienceTitle: 'Experiência do Jogador Aprimorada',
	challengesExperienceSubtitle:
		'Os desafios adicionam profundidade e emoção à jogabilidade, aprimorando a experiência geral do jogador.',
	freeSpins: 'Giros Grátis',
	giveGenerousPraiseToYour:
		'Ofereça incentivos generosos aos seus jogadores sem qualquer estresse. Nosso programa de Dedução de Bônus de Giros Grátis permitirá que você organize bônus regulares para seus jogadores sem esforço.',
	leverageTheFullPotential:
		'Aproveite todo o potencial desta ferramenta simples, mas poderosa, para premiar e reter seus jogadores.',
	engage: 'Engajar',
	letThePlayersEasilyTryNewContent: 'Permita aos jogadores experimentar facilmente novos conteúdos.',
	reward: 'Recompensar',
	addWelcomePackageOrBonusProgram: 'Adicione um pacote de boas-vindas ou programa de bônus.',
	retain: 'Reter',
	freeSpinsIsAGreatTool: 'Giros Grátis é uma ótima ferramenta para retenção de jogadores',
	gotIt: 'ENTENDI',
	//similar
	similarGamesTitle: 'Você também pode gostar',
	randomGame: 'Jogo aleatório',
	scanQrCodeToContactUs: 'ESCANEIE O CÓDIGO QR PARA NOS CONTACTAR',
	close: 'FECHAR',
	contactUs: 'ENTRE EM CONTATO',
	bRushChallenges: 'B-RUSH Challenges',
	aboutGame: 'SOBRE O JOGO',
	backToGame: 'VOLTAR AO JOGO'
};
