import { FormattedMessage } from 'react-intl';

const GamificationCard = ({ title, cardImg, onClick }) => {
	const handleClick = (event) => {
		if (onClick) {
			onClick();
		}
		event.stopPropagation();
	};

	return (
		<>
			<div className="gamification-card" onClick={handleClick}>
				<div className="gamification-card-img">
					<img src={cardImg} alt="" />
				</div>
				<div className="gamification-card-title">
					<FormattedMessage id={title} />
				</div>
			</div>
		</>
	);
};

export default GamificationCard;
