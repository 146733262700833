import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import closeContactIcon from '../../assets/closeContact.svg';
import { SELECTED_FEATURE, useGlobalState } from '../../hooks/useGlobalState';
import React from 'react';

const AboutGameModal = ({ setAboutGameModal }) => {
	const { state, dispatch } = useGlobalState();

	const getCurrentDate = (value) => {
		const date = new Date(value);
		const options = { year: 'numeric', month: 'short', day: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};

	const currentGame = state.games.find(({ GameID }) => state.gameID === GameID) || null;

	if (!state.games.length || !currentGame) return null;

	const { cardImg = '', GameName, MaxWin, GameType, ReleaseDate, Volatility, RTP } = currentGame;

	const maxMultiplier = currentGame['Max.multiplier'];
	const releaseDate = getCurrentDate(ReleaseDate);

	const getText = (textID) => (textID ? <FormattedMessage id={textID} /> : null);

	const modalID = 'about-game-modal';

	const handleClick = (event) => {
		const eventPath = event.nativeEvent.composedPath();

		const clickInsideModal = eventPath.some(({ id }) => id === modalID);

		if (!clickInsideModal) setAboutGameModal(false);
	};

	return (
		<Modal className="about-game-modal-overlay" onClick={handleClick}>
			<div className="about-game-modal" id={modalID}>
				<div className="about-game-modal-close" onClick={() => setAboutGameModal(false)}>
					<img src={closeContactIcon} alt="" />
				</div>

				{state.selectedFeature === SELECTED_FEATURE.DROP ? (
					<div>
						<div className="about-game-modal-title">
							<FormattedMessage id="dropsTitle" />
						</div>
						<div className="about-game-drop-text">
							<FormattedMessage id="dropsSubtitle" />
						</div>
						<div className="about-game-drop-container">
							<div className="about-game-drop-item">
								<div className="about-game-drop-item-title">
									<FormattedMessage id="dropsLoyaltyTitle" />
								</div>
								<div className="about-game-drop-item-text">
									<FormattedMessage id="dropsLoyaltySubtitle" />
								</div>
							</div>

							<div className="about-game-drop-item">
								<div className="about-game-drop-item-title">
									<FormattedMessage id="dropsMotivatesTitle" />
								</div>
								<div className="about-game-drop-item-text">
									<FormattedMessage id="dropsMotivatesSubtitle" />
								</div>
							</div>
						</div>

						<div className="about-game-drop-container">
							<div className="about-game-drop-item">
								<div className="about-game-drop-item-title">
									<FormattedMessage id="dropsToolTitle" />
								</div>
								<div className="about-game-drop-item-text">
									<FormattedMessage id="dropsToolSubtitle" />
								</div>
							</div>

							<div className="about-game-drop-item">
								<div className="about-game-drop-item-title">
									<FormattedMessage id="dropsExperienceTitle" />
								</div>
								<div className="about-game-drop-item-text">
									<FormattedMessage id="dropsExperienceSubtitle" />
								</div>
							</div>
						</div>
					</div>
				) : state.selectedFeature === SELECTED_FEATURE.CHALLENGES ? (
					<div>
						<div>
							<div className="about-game-modal-title">
								<FormattedMessage id="challengesTitle" />
							</div>
							<div className="about-game-drop-text">
								<FormattedMessage id="challengesSubtitle" />
							</div>
							<div className="about-game-drop-container">
								<div className="about-game-drop-item">
									<div className="about-game-drop-item-title">
										<FormattedMessage id="challengesMotivationTitle" />
									</div>
									<div className="about-game-drop-item-text">
										<FormattedMessage id="challengesMotivationSubtitle" />
									</div>
								</div>

								<div className="about-game-drop-item">
									<div className="about-game-drop-item-title">
										<FormattedMessage id="challengesRatesTitle" />
									</div>
									<div className="about-game-drop-item-text">
										<FormattedMessage id="challengesRatesSubtitle" />
									</div>
								</div>
							</div>

							<div className="about-game-drop-container">
								<div className="about-game-drop-item">
									<div className="about-game-drop-item-title">
										<FormattedMessage id="challengesToolTitle" />
									</div>
									<div className="about-game-drop-item-text">
										<FormattedMessage id="challengesToolSubtitle" />
									</div>
								</div>

								<div className="about-game-drop-item">
									<div className="about-game-drop-item-title">
										<FormattedMessage id="challengesExperienceTitle" />
									</div>
									<div className="about-game-drop-item-text">
										<FormattedMessage id="challengesExperienceSubtitle" />
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="about-game-modal-title">{GameName}</div>
						<div className="about-game-modal-container">
							<img src={cardImg} className="about-game-modal-img"></img>
							<div className="about-game-modal-info">
								<div>
									<div className="about-game-modal-row">
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">{getText('GameType')}</div>
											<div className="about-game-modal-item_value">
												{GameType ? getText(GameType) : '-'}
											</div>
										</div>
										<div className="about-game-modal-line"></div>
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">{getText('Volatility')}</div>
											<div className="about-game-modal-item_value">
												{Volatility ? getText(Volatility) : '-'}
											</div>
										</div>
										<div className="about-game-modal-line"></div>
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">RTP</div>
											<div className="about-game-modal-item_value">{RTP ? RTP : '-'}</div>
										</div>
									</div>

									<div className="about-game-modal-row">
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">{getText('releaseDate')}</div>
											<div className="about-game-modal-item_value">
												{releaseDate ? releaseDate : '-'}
											</div>
										</div>
										<div className="about-game-modal-line"></div>
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">
												{getText('maxMultiplier')}
											</div>
											<div className="about-game-modal-item_value">
												{maxMultiplier ? maxMultiplier : '-'}
											</div>
										</div>
										<div className="about-game-modal-line"></div>
										<div className="about-game-modal-item">
											<div className="about-game-modal-item_label">{getText('maxWin')}</div>
											<div className="about-game-modal-item_value">{MaxWin ? MaxWin : '-'}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="about-game-modal-btn-container">
					<button className="about-game-modal-btn" onClick={() => setAboutGameModal(false)}>
						<FormattedMessage id="backToGame" />
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default AboutGameModal;
