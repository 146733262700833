import { IntlProvider, FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { FILTERS } from '../services/utils/filters';
import FilterItem from '../components/FilterItem';
import DataService from '../services/DataService';
import cross from '../assets/cross.svg';
import nores from '../assets/nores.svg';
import closeIcon from '../assets/close-icon.svg';
import { Filters } from '../services/Filters';
import translations from '../services/languages';
import '../assets/styles/GamesFilters.css';
import GameRow from './GameRow';
import GameTypesFilters from './GameTypesFilters';
import GamesSearch from './GamesSearch';

const GAMES_PER_PAGE = 10;

function GamesFilters({ locale, handlePlayLink, setActiveGameFilters }) {
	const dataService = new DataService();
	const filterService = new Filters();
	const [filters, setFilters] = useState(FILTERS);
	const [initialData, setInitialData] = useState([]);
	const [data, setData] = useState([]);
	const [baseUrl, setBaseUrl] = useState('');
	const [gameType, setGameType] = useState('all');
	const [search, setSearch] = useState('');
	const [next, setNext] = useState(GAMES_PER_PAGE);
	const [isShowMore, setShowMore] = useState(true);

	const messages = translations[locale];

	const modalID = 'filter-popup';

	useEffect(() => {
		dataService.loadData(setData, setInitialData);
	}, []);

	useEffect(() => {
		fetch('/api/getBaseUrl')
			.then((response) => response.json())
			.then((data) => {
				setBaseUrl(data.baseUrl);
			})
			.catch((error) => console.error('Error:', error));
	}, []);

	useEffect(() => {
		const activeFilters = filters.filter((filter) => filter.touched);

		if (activeFilters.length) {
			activeFilters.forEach((filter) => {
				switch (filter.id) {
					case 'GameFeatures':
						setData(filterService.handleGameFeaturesFilters(data, initialData, filter.filters));
						break;

					case 'GameMechanics':
						setData(filterService.handleGameMechanicsFilters(data, initialData, filter.filters));
						break;

					case 'Lines':
						setData(filterService.handleLinesFilter(data, initialData, filter.filters));
						break;

					case 'GameTheme':
						setData(filterService.handleGameThemeFilters(data, initialData, filter.filters));
						break;

					case 'Volatility':
						setData(filterService.handleVolatilityFilters(data, initialData, filter.filters));
						break;
				}
			});
		} else {
			setData(initialData);
		}
	}, [filters]);

	const setNewFilters = (updatedFilter) => {
		setFilters(
			filters.map((filter) => {
				if (filter.id === updatedFilter.id) {
					return updatedFilter;
				} else {
					return filter;
				}
			})
		);
	};

	const handleResetFilters = () => {
		setFilters(FILTERS);
		setData(initialData);
		setGameType('all');
		setSearch('');
		setNext(GAMES_PER_PAGE);
	};

	const searchData = (filteredData) => {
		const searchedData = search
			? filteredData.filter((game) => game.GameName.toLowerCase().includes(search.toLowerCase()))
			: filteredData;
		const isAllData = gameType !== 'all' ? searchedData.filter((game) => game.GameType === gameType) : searchedData;
		const paginatedData = isAllData.slice(0, next);
		return paginatedData;
	};

	useEffect(() => {
		if (searchData(data).length >= next) {
			setShowMore(true);
		} else {
			setShowMore(false);
		}
	}, [next, searchData(data)]);

	const handleLoadMore = () => {
		setNext(next + GAMES_PER_PAGE);
	};

	const handleClickOutside = (event) => {
		const eventPath = event.nativeEvent.composedPath();
		const clickInsideModal = eventPath.some(({ id }) => id === modalID);
		if (!clickInsideModal) setActiveGameFilters(false);
	};

	return (
		<IntlProvider locale={locale} messages={messages}>
			<div className="games-filters-dropdown" onClick={handleClickOutside}>
				<div className="games-filters">
					<div id={modalID}>
						<div className="games-filters-close-container" onClick={() => setActiveGameFilters(false)}>
							<div className="games-filters-close">
								<img src={closeIcon} alt="" />
							</div>
						</div>
						<GamesSearch search={search} setSearch={setSearch} />
						<div className="games-filters-container">
							<GameTypesFilters locale={locale} gameType={gameType} setGameType={setGameType} />
							<div className="filters">
								{filters &&
									filters.map((filter, index) => (
										<FilterItem key={index} id={index} filter={filter} setFilters={setNewFilters} />
									))}
								<button className="dropdown-toggle" onClick={handleResetFilters}>
									<img src={cross} alt="cross"></img>
								</button>
							</div>
							<div className="game-rows">
								{searchData(data).length > 0 &&
									searchData(data).map((game) => {
										return (
											<GameRow
												key={game.ItemID}
												gameName={game.GameName}
												gameType={game.GameType}
												cardHorizontalImg={game.cardHorizontalImg}
												playLink={handlePlayLink(game.GameID)}
												gameID={game.GameID}
												handleClick={() => setActiveGameFilters(false)}
												gameDemoLink={game.GameDemoLink}
											/>
										);
									})}
								{searchData(data).length === 0 && (
									<div className="no-result">
										<img src={nores} alt="" />
										<h2>
											<FormattedMessage id="noSearchResults" />
										</h2>
										<p>
											<FormattedMessage id="noGamesWithSearchEntry" />
										</p>
									</div>
								)}
							</div>
							<div>
								{isShowMore && (
									<button className="more-btn" onClick={handleLoadMore}>
										<FormattedMessage id="showMore" />
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</IntlProvider>
	);
}

export default GamesFilters;
