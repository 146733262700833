const TOP_LATAM_GAMES = [
	'WildTiger',
	'AztecClusters',
	'BurningChilliX',
	'Aviamasters',
	'Plinko2',
	'SpaceXY',
	'WildCashX9990',
	'LuckyDucky',
	'MergeUp',
	'FishingClub',
	'Plinko',
	'AliceWonderLuck',
	'WildCardGang',
	'WildMoonThieves',
	'MineGems',
	'Minesweeper',
	'HotChilliBells'
];

export const isTopLatamGame = (game) => {
	return TOP_LATAM_GAMES.includes(game.GameID);
};

export const filterTopLatamGames = (games) => {
	return games.filter((game) => {
		return isTopLatamGame(game);
	});
};
