import closeContactIcon from '../../../assets/closeContact.svg';
import enQR from './QR.png';
import brQR from './QR-br.png';
import esQR from './QR-es.png';
import { FormattedMessage } from 'react-intl';
import Modal from '../../Modal';
import { useGlobalState } from '../../../hooks/useGlobalState';

const ContactUsModal = ({ setContactUsModal }) => {
	const { state, dispatch } = useGlobalState();
	const modalID = 'contact-us-modal';

	const QRImages = {
		'en': enQR,
		'es': esQR,
		'pt': brQR
	}

	const handleClick = (event) => {
		const eventPath = event.nativeEvent.composedPath();

		const clickInsideModal = eventPath.some(({ id }) => id === modalID);

		if (!clickInsideModal) setContactUsModal(false);
	};

	return (
		<Modal className="contact-us-modal-overlay" onClick={handleClick}>
			<div className="contact-us-modal" id={modalID}>
				<div className="contact-us-modal-close" onClick={() => setContactUsModal(false)}>
					<img src={closeContactIcon} alt="" />
				</div>

				<div className="contact-us-modal-title">
					<FormattedMessage id="scanQrCodeToContactUs" />
				</div>

				<div className="contact-us-modal-container">
					<div className="contact-us-modal-img">
						<img src={QRImages[state.language]} alt="QR img" />
					</div>
				</div>

				<div className="contact-us-modal-btn-container">
					<button className="contact-us-modal-btn" onClick={() => setContactUsModal(false)}>
						<FormattedMessage id="close" />
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ContactUsModal;
